import React from 'react'
import Navbar from '../../Components/Navbar'
import AboutUs from '../../Components/AboutUs'
import Footer from '../../Components/Footer'

const Home = () => {
  return (
    <>
       <Navbar/>
       <AboutUs/>
       <Footer/>
       
    </>
  )
}

export default Home